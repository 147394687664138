import React, { Component } from "react";
import { Link } from "gatsby"
import "@fontsource/crimson-text" 
import Logo from "../../static/img/tot-logo.svg";
import Toggler from "./toggler";

class Layout extends Component {
  render() {
    const { title, children} = this.props
    return (
      <div className="site-container">
        <header className="header-container">
          <Link
            className="header-title"
            to={`/`}
          >
          <Logo />
            {/* title */}
          </Link>
          <div className="nav-container">
            <ul className="header-nav">
              <li><Link to={`/blog`}>Blog</Link></li>
              <li><Link to={`/about`}>About</Link></li>
             {/* <li><Link to={`/resources`}>Resources</Link></li> */}
            </ul>
            <ul className="header-link">
            <li>
              <Toggler/>
            </li>
            </ul>
          </div>
        </header>
        <main>{children}</main>
        <footer className="footer-copyright">
          © {new Date().getFullYear()} {title}
        </footer>
      </div>
    )
  }
}

export default Layout
