import React from 'react';
import { ThemeToggler } from "gatsby-plugin-dark-mode";


const Toggler = () =>
    
    <div className="toggler">
      <ThemeToggler>{({ theme, toggleTheme }) => (
        <label className="tog">
          <input
            type="checkbox"
            onChange={e =>
              toggleTheme(e.target.checked ? "dark" : "light")
            }
            checked={theme === "dark"}
            className="tog-checkbox"
          />
          {theme === "dark" ? (
            <div className="tog-icon">
              <svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.46 34.36"><path d="M18.18 34.36A18.17 18.17 0 019.33.31a2.42 2.42 0 012.61.16 2.39 2.39 0 011 2.41l-1.3-.1 1.23.22a15.66 15.66 0 0010.21 17.68 15.82 15.82 0 008.47.53 2.44 2.44 0 012.66 3.47 18.18 18.18 0 01-16.03 9.68zM10.41 2.57a15.68 15.68 0 1021.51 21.11 18.49 18.49 0 01-9.65-.64A18.18 18.18 0 0110.41 2.57z" /></svg>
            </div>
          ) : (
            <div className="tog-icon">
              <svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.46 34.36"><path d="M18.18 34.36A18.17 18.17 0 019.33.31a2.42 2.42 0 012.61.16 2.39 2.39 0 011 2.41l-1.3-.1 1.23.22a15.66 15.66 0 0010.21 17.68 15.82 15.82 0 008.47.53 2.44 2.44 0 012.66 3.47 18.18 18.18 0 01-16.03 9.68zM10.41 2.57a15.68 15.68 0 1021.51 21.11 18.49 18.49 0 01-9.65-.64A18.18 18.18 0 0110.41 2.57z" /></svg>
            </div>
          )}
        </label>
      )}</ThemeToggler>
    </div>
 
 export default Toggler;